import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cricket: {
    showSeriesDetailsPopup: { status: false, helperData: null },
    showPlayerInfoPopup: { status: false, helperData: null },
    showMatchReportPopup: { status: false, helperData: null },
    setMatchDetailsPageTab: { tab: 1 },
    addCricketContestPopup: { status: false, helperData: null },
    winningsPopup: { status: false, helperData: null },
    showDuplicateContextPopup: { status: false, helperData: null },
    showPreviewPopup: { status: false, helperData: null },
    showEditTeamPopup: { status: false, helperData: null },
    showEditMatchTimePopup: { status: false, helperData: null },

    showPreviewSeriesNamePopup: { status: false, helperData: null },
    showEditSeriesNamePopup: { status: false, helperData: null },

    showEditPlayerDetailsPopup: { status: false, helperData: null },
    showAddPlayersPopup: { status: false, helperData: null },
    showEditPlayersPopup: { status: false, helperData: null },
  },
};

const sportsSlice = createSlice({
  name: "sports",
  initialState: initialState,
  reducers: {
    //* show Edit Players Popup
    openShowEditPlayersPopup(state, action) {
      state.cricket.showEditPlayersPopup.helperData = action.payload;
      state.cricket.showEditPlayersPopup.status = true;
    },
    closeShowEditPlayersPopup(state, action) {
      state.cricket.showEditPlayersPopup.status = false;
      state.cricket.showEditPlayersPopup.helperData = null;
    },

    //* show Add Players Popup
    openShowAddPlayersPopup(state, action) {
      state.cricket.showAddPlayersPopup.helperData = action.payload;
      state.cricket.showAddPlayersPopup.status = true;
    },
    closeShowAddPlayersPopup(state, action) {
      state.cricket.showAddPlayersPopup.status = false;
      state.cricket.showAddPlayersPopup.helperData = null;
    },

    //* show Edit Player Details Popup
    openShowEditPlayerDetailsPopup(state, action) {
      state.cricket.showEditPlayerDetailsPopup.helperData = action.payload;
      state.cricket.showEditPlayerDetailsPopup.status = true;
    },
    closeShowEditPlayerDetailsPopup(state, action) {
      state.cricket.showEditPlayerDetailsPopup.status = false;
      state.cricket.showEditPlayerDetailsPopup.helperData = null;
    },

    //* show Edit SeriesName Popup
    openShowEditSeriesNamePopup(state, action) {
      state.cricket.showEditSeriesNamePopup.helperData = action.payload;
      state.cricket.showEditSeriesNamePopup.status = true;
    },
    closeShowEditSeriesNamePopup(state, action) {
      state.cricket.showEditSeriesNamePopup.status = false;
      state.cricket.showEditSeriesNamePopup.helperData = null;
    },

    //* show Preview SeriesNamePopup
    openShowPreviewSeriesNamePopup(state, action) {
      state.cricket.showPreviewSeriesNamePopup.helperData = action.payload;
      state.cricket.showPreviewSeriesNamePopup.status = true;
    },
    closeShowPreviewSeriesNamePopup(state, action) {
      state.cricket.showPreviewSeriesNamePopup.status = false;
      state.cricket.showPreviewSeriesNamePopup.helperData = null;
    },
    //* show EditMatchTimePopup
    openShowEditMatchTimePopup(state, action) {
      state.cricket.showEditMatchTimePopup.helperData = action.payload;
      state.cricket.showEditMatchTimePopup.status = true;
    },
    closeShowEditMatchTimePopup(state, action) {
      state.cricket.showEditMatchTimePopup.status = false;
      state.cricket.showEditMatchTimePopup.helperData = null;
    },

    //* show EditTeamPopup
    openShowEditTeamPopup(state, action) {
      state.cricket.showEditTeamPopup.helperData = action.payload;
      state.cricket.showEditTeamPopup.status = true;
    },
    closeShowEditTeamPopup(state, action) {
      state.cricket.showEditTeamPopup.status = false;
      state.cricket.showEditTeamPopup.helperData = null;
    },

    //* show PreviewPopup
    openShowPreviewPopup(state, action) {
      state.cricket.showPreviewPopup.helperData = action.payload;
      state.cricket.showPreviewPopup.status = true;
    },
    closeShowPreviewPopup(state, action) {
      state.cricket.showPreviewPopup.status = false;
      state.cricket.showPreviewPopup.helperData = null;
    },

    //* show Series Details Popup
    openShowSeriesDetailsPopup(state, action) {
      state.cricket.showSeriesDetailsPopup.helperData = action.payload;
      state.cricket.showSeriesDetailsPopup.status = true;
    },
    closeShowSeriesDetailsPopup(state, action) {
      state.cricket.showSeriesDetailsPopup.status = false;
      state.cricket.showSeriesDetailsPopup.helperData = null;
    },

    //* show Player Info Popup
    openShowPlayerInfoPopup(state, action) {
      state.cricket.showPlayerInfoPopup.helperData = action.payload;
      state.cricket.showPlayerInfoPopup.status = true;
    },
    closeShowPlayerInfoPopup(state, action) {
      state.cricket.showPlayerInfoPopup.status = false;
      state.cricket.showPlayerInfoPopup.helperData = null;
    },

    //* show Match Report Popup
    openShowMatchReportPopup(state, action) {
      state.cricket.showMatchReportPopup.helperData = action.payload;
      state.cricket.showMatchReportPopup.status = true;
    },
    closeShowMatchReportPopup(state, action) {
      state.cricket.showMatchReportPopup.status = false;
      state.cricket.showMatchReportPopup.helperData = null;
    },

    //* show Match Report Popup
    setMatchDetailsPageTab(state, action) {
      state.cricket.setMatchDetailsPageTab.tab = action.payload;
    },

    //**winningsPopup */
    openWinningsPopup(state, action) {
      state.cricket.winningsPopup.helperData = action.payload;
      state.cricket.winningsPopup.status = true;
    },
    closeWinningsPopup(state, action) {
      state.cricket.winningsPopup.status = false;
      state.cricket.winningsPopup.helperData = null;
    },

    //**  show DuplicateContext Popup*/
    openShowDuplicateContextPopup(state, action) {
      state.cricket.showDuplicateContextPopup.helperData = action.payload;
      state.cricket.showDuplicateContextPopup.status = true;
    },
    closeShowDuplicateContextPopup(state, action) {
      state.cricket.showDuplicateContextPopup.status = false;
      state.cricket.showDuplicateContextPopup.helperData = null;
    },
  },
});

export const {
  openShowSeriesDetailsPopup,
  closeShowSeriesDetailsPopup,
  openShowPlayerInfoPopup,
  closeShowPlayerInfoPopup,
  openShowMatchReportPopup,
  closeShowMatchReportPopup,
  setMatchDetailsPageTab,
  openWinningsPopup,
  closeWinningsPopup,
  openShowDuplicateContextPopup,
  closeShowDuplicateContextPopup,
  openShowPreviewPopup,
  closeShowPreviewPopup,
  openShowEditTeamPopup,
  closeShowEditTeamPopup,
  openShowEditMatchTimePopup,
  closeShowEditMatchTimePopup,

  openShowEditSeriesNamePopup,
  closeShowEditSeriesNamePopup,
  openShowPreviewSeriesNamePopup,
  closeShowPreviewSeriesNamePopup,
  openShowEditPlayerDetailsPopup,
  closeShowEditPlayerDetailsPopup,
  openShowAddPlayersPopup,
  closeShowAddPlayersPopup,
  openShowEditPlayersPopup,
  closeShowEditPlayersPopup,
} = sportsSlice.actions;
export default sportsSlice.reducer;
