import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  UserHistoryPopup: { status: false, helperData: null },
};

const history = createSlice({
  name: "history",
  initialState: initialState,
  reducers: {
    openUserHistoryPopup(state, action) {
      state.UserHistoryPopup.helperData = action.payload;
      state.UserHistoryPopup.status = true;
    },
    closeUserHistoryPopup(state, action) {
      state.UserHistoryPopup.status = false;
      state.UserHistoryPopup.helperData = null;
    },
  },
});
export const { openUserHistoryPopup, closeUserHistoryPopup } = history.actions;
export default history.reducer;
