import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerTicketPopup: { status: false, helperData: null },
  customerTicketActionPopup: { status: false, helperData: null },
  customerStaffListingPopup: { status: false, helperData: null },
  customerMyTicketActionPopup: { status: false, helperData: null },
  showTicketDetailsPopup: { status: false, helperData: null },
  showInternalIssuedTicketPopup: { status: false, helperData: null },
  showResendTicketPopup: { status: false, helperData: null },
  showIssuedTicketPopup: { status: false, helperData: null },
};

const ticket = createSlice({
  name: "ticket",
  initialState: initialState,
  reducers: {
    //* show Create CustomerTicketPopup
    openShowIssuedTicketPopup(state, action) {
      state.showIssuedTicketPopup.helperData = action.payload;
      state.showIssuedTicketPopup.status = true;
    },
    closeShowIssuedTicketPopup(state, action) {
      state.showIssuedTicketPopup.status = false;
      state.showIssuedTicketPopup.helperData = null;
    },
    //* show InternalIssuedTicketPopup
    openShowInternalIssuedTicketPopup(state, action) {
      state.showInternalIssuedTicketPopup.helperData = action.payload;
      state.showInternalIssuedTicketPopup.status = true;
    },
    closeShowInternalIssuedTicketPopup(state, action) {
      state.showInternalIssuedTicketPopup.status = false;
      state.showInternalIssuedTicketPopup.helperData = null;
    },
    //* show Ticket Details Popup
    openShowTicketDetailsPopup(state, action) {
      state.showTicketDetailsPopup.helperData = action.payload;
      state.showTicketDetailsPopup.status = true;
    },
    closeShowTicketDetailsPopup(state, action) {
      state.showTicketDetailsPopup.status = false;
      state.showTicketDetailsPopup.helperData = null;
    },

    openCustomerTicketPopup(state, action) {
      state.customerTicketPopup.helperData = action.payload;
      state.customerTicketPopup.status = true;
    },
    closeCustomerTicketPopup(state, action) {
      state.customerTicketPopup.status = false;
      state.customerTicketPopup.helperData = null;
    },

    openCustomerTicketActionPopup(state, action) {
      state.customerTicketActionPopup.helperData = action.payload;
      state.customerTicketActionPopup.status = true;
    },
    closeCustomerTicketActionPopup(state, action) {
      state.customerTicketActionPopup.status = false;
      state.customerTicketActionPopup.helperData = null;
    },

    openCustomerStaffListingPopup(state, action) {
      state.customerStaffListingPopup.helperData = action.payload;
      state.customerStaffListingPopup.status = true;
    },
    closeCustomerStaffListingPopup(state, action) {
      state.customerStaffListingPopup.status = false;
      state.customerStaffListingPopup.helperData = null;
    },

    openCustomerMyTicketActionPopup(state, action) {
      state.customerMyTicketActionPopup.helperData = action.payload;
      state.customerMyTicketActionPopup.status = true;
    },
    closeCustomerMyTicketActionPopup(state, action) {
      state.customerMyTicketActionPopup.status = false;
      state.customerMyTicketActionPopup.helperData = null;
    },

    //* show ResendTicketPopup
    openShowResendTicketPopup(state, action) {
      state.showResendTicketPopup.helperData = action.payload;
      state.showResendTicketPopup.status = true;
    },
    closeShowResendTicketPopup(state, action) {
      state.showResendTicketPopup.status = false;
      state.showResendTicketPopup.helperData = null;
    },
  },
});
export const {
  openCustomerTicketPopup,
  closeCustomerTicketPopup,
  openCustomerTicketActionPopup,
  closeCustomerTicketActionPopup,
  openCustomerStaffListingPopup,
  closeCustomerStaffListingPopup,
  openCustomerMyTicketActionPopup,
  closeCustomerMyTicketActionPopup,
  openShowTicketDetailsPopup,
  closeShowTicketDetailsPopup,
  openShowInternalIssuedTicketPopup,
  closeShowInternalIssuedTicketPopup,
  openShowIssuedTicketPopup,
  closeShowIssuedTicketPopup,
  openShowResendTicketPopup,
  closeShowResendTicketPopup,
} = ticket.actions;
export default ticket.reducer;
