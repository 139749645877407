import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adDetailsPopup: { status: false, helperData: null },
};

const advertisementsSlice = createSlice({
  name: "advertisements",
  initialState: initialState,
  reducers: {
    openAdDetailsPopup(state, action) {
      state.adDetailsPopup.helperData = action.payload;
      state.adDetailsPopup.status = true;
    },
    closeAdDetailsPopup(state, action) {
      state.adDetailsPopup.status = false;
      state.adDetailsPopup.helperData = null;
    },
  },
});

export const { openAdDetailsPopup, closeAdDetailsPopup } =
  advertisementsSlice.actions;
export default advertisementsSlice.reducer;
