import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cricket: {
    showPointsPopup: { status: false, helperData: null },
    showEditPointsPopup: { status: false, helperData: null },
    contests: {
      showPointSystemPopup: { status: false, helperData: null },
      // selectedPointsSystemDetails: { helperData: null },
    },
    showNftDetailsPopup: { status: false, helperData: null },
  },
};

const fantasySportsSlice = createSlice({
  name: "fantasySports",
  initialState: initialState,
  reducers: {
    //* show Points Details Popup
    openShowNftDetailsPopup(state, action) {
      state.cricket.showNftDetailsPopup.helperData = action.payload;
      state.cricket.showNftDetailsPopup.status = true;
    },
    closeShowNftDetailsPopup(state, action) {
      state.cricket.showNftDetailsPopup.status = false;
      state.cricket.showNftDetailsPopup.helperData = null;
    },

    //* show Points Details Popup
    openShowPointsPopup(state, action) {
      state.cricket.showPointsPopup.helperData = action.payload;
      state.cricket.showPointsPopup.status = true;
    },
    closeShowPointsPopup(state, action) {
      state.cricket.showPointsPopup.status = false;
      state.cricket.showPointsPopup.helperData = null;
    },

    //* show Edit Points Details Popup
    openShowEditPointsPopup(state, action) {
      state.cricket.showEditPointsPopup.helperData = action.payload;
      state.cricket.showEditPointsPopup.status = true;
    },
    closeShowEditPointsPopup(state, action) {
      state.cricket.showEditPointsPopup.status = false;
      state.cricket.showEditPointsPopup.helperData = null;
    },

    //* show PointSystem Popup
    openShowPointSystemPopup(state, action) {
      state.cricket.contests.showPointSystemPopup.helperData = action.payload;
      state.cricket.contests.showPointSystemPopup.status = true;
    },
    closeShowPointSystemPopup(state, action) {
      state.cricket.contests.showPointSystemPopup.status = false;
      state.cricket.contests.showPointSystemPopup.helperData = null;
    },
    // //* set selected PointsSystem Details
    // setSelectedPointsSystemDetails(state, action) {
    //   state.cricket.contests.selectedPointsSystemDetails.helperData =
    //     action.payload;
    // },
    // //* reset selected PointsSystem Details
    // resetSelectedPointsSystemDetails(state, action) {
    //   state.cricket.contests.selectedPointsSystemDetails.helperData = null;
    // },
  },
});

export const {
  openShowPointsPopup,
  closeShowPointsPopup,
  openShowEditPointsPopup,
  closeShowEditPointsPopup,
  openShowPointSystemPopup,
  closeShowPointSystemPopup,
  // setSelectedPointsSystemDetails,
  // resetSelectedPointsSystemDetails,
  openShowNftDetailsPopup,
  closeShowNftDetailsPopup,
} = fantasySportsSlice.actions;
export default fantasySportsSlice.reducer;
