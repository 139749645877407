import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAddNftPopup: { status: false, helperData: null },
  showNftStatusPopup: { status: false, helperData: null },
};

const nftsSlice = createSlice({
  name: "nfts",
  initialState: initialState,
  reducers: {
    //* show NftStatusPopup
    openShowNftStatusPopup(state, action) {
      state.showNftStatusPopup.helperData = action.payload;
      state.showNftStatusPopup.status = true;
    },
    closeShowNftStatusPopup(state, action) {
      state.showNftStatusPopup.status = false;
      state.showNftStatusPopup.helperData = null;
    },
    //** show AddNftPopup
    openShowAddNftPopup(state, action) {
      state.showAddNftPopup.helperData = action.payload;
      state.showAddNftPopup.status = true;
    },
    closeShowAddNftPopup(state, action) {
      state.showAddNftPopup.status = false;
      state.showAddNftPopup.helperData = null;
    },
  },
});

export const {
  openShowAddNftPopup,
  closeShowAddNftPopup,
  openShowNftStatusPopup,
  closeShowNftStatusPopup,
} = nftsSlice.actions;
export default nftsSlice.reducer;
