import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAddBonusPopup: { status: false, helperData: null },
 selectedBonuses: []
};

const bonusSlice = createSlice({
  name: "bonus",
  initialState: initialState,
  reducers: {
   
    //** show AddNftPopup
    openShowAddBonusPopup(state, action) {
      state.showAddBonusPopup.helperData = action.payload;
      state.showAddBonusPopup.status = true;
    },
    closeShowAddBonusPopup(state, action) {
      state.showAddBonusPopup.status = false;
      state.showAddBonusPopup.helperData = null;
    },
    addBonus(state, action){
    state.selectedBonuses = action.payload

    },
  clearBonus(state, action){    
    state.selectedBonuses = []
    }
  }
  
});

export const {
  openShowAddBonusPopup,closeShowAddBonusPopup,addBonus,removeBonus,clearBonus
} = bonusSlice.actions;
export default bonusSlice.reducer;
