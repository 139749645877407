// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import todo from "@src/views/apps/todo/store";
import chat from "@src/views/apps/chat/store";
// import users from "@src/views/apps/user/store";
import email from "@src/views/apps/email/store";
import invoice from "@src/views/apps/invoice/store";
import calendar from "@src/views/apps/calendar/store";
import ecommerce from "@src/views/apps/ecommerce/store";
import dataTables from "@src/views/tables/data-tables/store";
import permissions from "@src/views/apps/roles-permissions/store";
import restApi from "./api/restApi";
import misc from "./slices/misc";

//**my slices */
import sports from "./slices/sports";
import fantasySports from "./slices/fantasySports";
import cricket from "./slices/cricket";
import fakeRestApi from "./api/fakeRestApi";
import ticket from "./slices/ticket";
import transaction from "./slices/transaction";
import chats from "./slices/chats";
import users from "./slices/users";

//...my slices..
import createAd from "./slices/createAd";
import history from "./slices/history";
import advertisements from "./slices/advertisements";
import nfts from "./slices/nfts";
import bonus from "./slices/bonus";
import kycBank from "./slices/kycBank";
import videos from "./slices/videos";

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  // users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  misc,
  cricket,

  [restApi.reducerPath]: restApi.reducer,

  //*Slices
  sports,
  fantasySports,
  ///MY Slices
  ticket,
  transaction,
  chats,
  users,

  //..myslices....
  createAd,
  history,
  advertisements,
  nfts,
  bonus,
  kycBank,
  videos,
  //fakerestApi
  [fakeRestApi.reducerPath]: fakeRestApi.reducer,
};

export default rootReducer;
