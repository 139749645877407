import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUserDetailsTab: { currentTab: 1, pageSlug: null },
  prints: {
    showPanKycDetailsPrintPopup: { status: false, helperData: null },
  },
};

const usersSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    //* set Current UserDetails Tab
    setCurrentUserDetailsTab(state, action) {
      state.currentUserDetailsTab.currentTab = action.payload;
    },
    setCurrentUserDetailsPageSlug(state, action) {
      state.currentUserDetailsTab.pageSlug = action.payload;
    },
    resetCurrentUserDetailsTab(state, action) {
      state.currentUserDetailsTab.currentTab = 1;
      state.currentUserDetailsTab.pageSlug = null;
    },

    //***Prints --start */
    //* show Pan Kyc Details Print Popup
    openShowPanKycDetailsPrintPopup(state, action) {
      state.prints.showPanKycDetailsPrintPopup.helperData = action.payload;
      state.prints.showPanKycDetailsPrintPopup.status = true;
    },
  },
});

export const {
  setCurrentUserDetailsTab,
  setCurrentUserDetailsPageSlug,
  resetCurrentUserDetailsTab,
  openShowPanKycDetailsPrintPopup,
} = usersSlice.actions;
export default usersSlice.reducer;
