import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  qrimagePopup: { status: false, helperData: null },
  imagePopup: { status: false, helperData: null },
};

const miscSlice = createSlice({
  name: "misc",
  initialState: initialState,
  reducers: {
    openQRImagePopup(state, action) {
      state.qrimagePopup.helperData = action.payload;
      state.qrimagePopup.status = true;
    },

    closeQRImagePopup(state, action) {
      state.qrimagePopup.status = false;
      state.qrimagePopup.helperData = null;
    },

    openImagePopup(state, action) {
      state.imagePopup.helperData = action.payload;
      state.imagePopup.status = true;
    },
    
    closeImagePopup(state, action) {
      state.imagePopup.status = false;
      state.imagePopup.helperData = null;
    },
  },
});

export const {
  openQRImagePopup,
  closeQRImagePopup,
  openImagePopup,
  closeImagePopup,
} = miscSlice.actions;
export default miscSlice.reducer;
