import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUserData } from "../../utility/Utils";

const baseUrl = "https://jsonplaceholder.typicode.com/";

const fakeRestApi = createApi({
  reducerPath: "fakeRestApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,

    prepareHeaders: (headers) => {
      const token = getUserData()?.tokens?.cricket_access;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getDummyUsers: builder.query({
      query: (data) => `users`,
    }),
  }),
});

export const { useGetDummyUsersQuery } = fakeRestApi;

export default fakeRestApi;
