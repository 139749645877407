import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  UsercreateAdPopup: { status: false, helperData: null },
  UserTodayPopUp: { status: false, helperData: null },
};

const createAd = createSlice({
  name: "createAd",
  initialState: initialState,
  reducers: {
    openUsercreateAdPopup(state, action) {
      state.UsercreateAdPopup.helperData = action.payload;
      state.UsercreateAdPopup.status = true;
    },
    closeUsercreateAdPopup(state, action) {
      state.UsercreateAdPopup.status = false;
      state.UsercreateAdPopup.helperData = null;
    },

    openUserTodayPopUp(state, action) {
      state.UserTodayPopUp.helperData = action.payload;
      state.UserTodayPopUp.status = true;
    },
    closeUserTodayPopUp(state, action) {
      state.UserTodayPopUp.status = false;
      state.UserTodayPopUp.helperData = null;
    },
  },
});
export const {
  openUsercreateAdPopup,
  closeUsercreateAdPopup,
  openUserTodayPopUp,
  closeUserTodayPopUp,
} = createAd.actions;
export default createAd.reducer;
