// ** Logo
// import logo from "@src/assets/images/logo/logo.png";
// ** Config
import themeConfig from "@configs/themeConfig";
import spiner from "@src/assets/my-images/loading1.gif";

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader">
      <div className="d-flex flex-row align-items-center">
        <span className="brand-logo">
          <img src={themeConfig.app.appLogoImage} alt="logo" />
        </span>
        <h2 className="brand-text mb-0 px-1 text-primary">
          {themeConfig.app.appName}
        </h2>
      </div>
      <img style={{ height: "150px", widows: "150px" }} src={spiner} alt="" />
      {/* <img className='fallback-logo' src={logo} alt='logo' /> */}
      {/* <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div> */}
    </div>
  );
};

export default SpinnerComponent;
