import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUserData } from "@utils";

const baseUrl = process.env.REACT_APP_BACKEND_BASEURL;
// const loginUrl = process.env.REACT_APP_LOGIN_URL;
// const accountsUrl = process.env.REACT_APP_ACCOUNTS_URL;
const cricketDataAdminUrl = process.env.REACT_APP_CRICDATA_ADMIN_URL;
const accountsAdminUrl = process.env.REACT_APP_ACCOUNTS_ADMIN_URL;
const accountsClientUrl = process.env.REACT_APP_ACCOUNTS_CLIENT_URL;
const cricketFantasyAdminUrl = process.env.REACT_APP_CRICKET_FANTASY_ADMIN_URL;
const bitgoAdminUrl = process.env.REACT_APP_BITGO_ADMIN_URL;
const walletAdminUrl = process.env.REACT_APP_WALLETS_ADMIN_URL;
const walletAdminV2Url = process.env.REACT_APP_WALLETS_ADMIN_V2_URL;
const ticketsAdminUrl = process.env.REACT_APP_TICKETS_ADMIN_URL;
const chatAdminUrl = process.env.REACT_APP_CHAT_ADMIN_URL;
const adsAdmin = process.env.REACT_APP_ADS_ADMIN_URL;
const pointsAdminUrl = process.env.REACT_APP_POINTS_ADMIN_URL;
const nftAdminUrl = process.env.REACT_APP_NFT_ADMIN_URL;
const kycAdminUrl = process.env.REACT_APP_KYC_ADMIN_URL;
const feedAdminUrl = process.env.REACT_APP_FEED_ADMIN_URL;
const videoGalleryAdminUrl = process.env.REACT_APP_VIDEO_GALLERY_ADMIN_URL;
//*** */
// const cricketDataAdminUrl = process.env.REACT_APP_CRICKET_DATA_ADMIN_URL;
// const cricketFantasyAdminUrl = process.env.REACT_APP_CRICKET_FANTASY_ADMIN_URL;

const restApi = createApi({
  reducerPath: "restApi",

  baseQuery: fetchBaseQuery({
    baseUrl,

    prepareHeaders: (headers) => {
      const token = getUserData()?.access;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: [
    "Match",
    "Series",
    "Players",
    "Teams",
    "Contests",
    "Wallets",
    //***--- */
    "CricketSeries",
    "CricketMatches",
    "CricketContests",
    "Points",
    "CricketFantasyFormatRules",
    "CricketContestMatchAssignedPoints",
    "Users",
    "Chats",
    "Tickets",
    "Ads",
    "Transactions",
    "CricketTeams",
    "BulkUpload",
    "Nfts",
    "NftWallets",
    "NftStatus",
    "MatchPlayers",
    "ContestTransactions",
    "Bonus",
  ],

  endpoints: (builder) => ({
    sendOtp: builder.mutation({
      query: (body) => ({
        url: `${accountsAdminUrl}user-phone-check/`,
        method: "POST",
        body,
      }),
    }),

    login: builder.mutation({
      query: (body) => ({
        method: `${accountsAdminUrl}user-phone-login/`,
        method: "POST",
        body,
      }),
    }),

    checkToken: builder.mutation({
      query: (body) => ({
        url: `api/token/verify/`,
        method: "POST",
        body,
      }),
    }),

    // ***Cricket***
    // **Match**

    // Matches
    getAllMatches: builder.query({
      query: (data) =>
        `${cricketDataAdminUrl}match-list/?limit=${data.limit}&offset=${data.offset}`,
      providesTags: ["Match"],
    }),

    // View Details
    getAllMatchesDetails: builder.query({
      query: (data) => `${cricketDataAdminUrl}match-list/${data.slug}/`,
      providesTags: ["Match"],
    }),

    // **Series**

    // Series
    getAllSeries: builder.query({
      query: (data) => `${cricketDataAdminUrl}series-list/`,
      providesTags: ["Series"],
    }),

    // View Details
    // Series Details
    getAllSeriesDetails: builder.query({
      query: (data) => `${cricketDataAdminUrl}series-list/${data.slug}/`,
      providesTags: ["Series"],
    }),

    // View Details
    // Matches
    getAllSeriesMatches: builder.query({
      query: (data) =>
        `${cricketDataAdminUrl}series-matches-list/${data.slug}/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      providesTags: ["Match", "Series"],
    }),

    //**Teams */

    //Teams
    getAllTeams: builder.query({
      query: (data) =>
        `${cricketDataAdminUrl}team-list/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      providesTags: ["Teams"],
    }),

    //**Players */

    //Players
    getAllPlayers: builder.query({
      query: (data) =>
        `${cricketDataAdminUrl}team-players-list/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      providesTags: ["Players"],
    }),

    //Players
    getAllPlayersByTeamSlug: builder.query({
      query: (data) =>
        `${cricketDataAdminUrl}team-players-list/?limit=${data.limit}&offset=${data.offset}&match=${data.match_slug}&team=${data.team_slug}&search=${data.search}`,
      providesTags: ["Players"],
    }),

    // ** Users
    // ** Get All Users
    getAllUsers: builder.query({
      query: (data) =>
        data?.status
          ? `${accountsAdminUrl}all-users/?limit=${data.limit}&offset=${
              data.offset
            }&search=${data.search}&is_active=${
              data.status === "Active" ? true : false
            }`
          : data?.search
          ? `${accountsAdminUrl}all-users/?limit=${data.limit}&search=${data.search}`
          : `${accountsAdminUrl}all-users/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,

      providesTags: ["Users"],
    }),

    //Update User Active Inactive
    UpdateUserActiveInactive: builder.mutation({
      query: (data) => ({
        url: `${accountsAdminUrl}all-users/${data?.slug}/`,
        method: `PUT`,
        body: data.data,
      }),

      invalidatesTags: ["Users"],
    }),

    getUserDetails: builder.query({
      query: (data) => `${accountsAdminUrl}all-users/${data?.slug}/`,
    }),

    // ** get User Wallets
    getUserWallets: builder.query({
      query: (data) => `${accountsAdminUrl}users-wallets/${data.slug}/`,
    }),

    // ** get User Contests
    getUserContests: builder.query({
      query: (data) => `${accountsAdminUrl}user-contests/${data.slug}/`,
    }),

    // ** Get Match Contests
    getContestsByMatch: builder.query({
      query: (data) =>
        `${cricketFantasyAdminUrl}contests-0f-match/${data.slug}/`,

      providesTags: ["Contests"],
    }),

    // ** Get All Wallets
    getAllWallets: builder.query({
      query: (data) =>
        `${bitgoAdminUrl}wallets-all/?limit=${data.limit}&offset=${data?.offset}`,
    }),

    // ** get Wallet Transactions
    getWalletTransactions: builder.query({
      query: (data) => `${bitgoAdminUrl}wallet-transactions/${data.slug}/`,
    }),

    // ** get Match Team

    // ** Get Team Details By Slug
    getUserSelectionTeam: builder.query({
      query: (data) =>
        `${cricketFantasyAdminUrl}user-contest-team-details/${data.slug}/`,
    }),

    // // ** add Contest
    // addContest: builder.mutation({
    //   query: (body) => ({
    //     url: `${cricketFantasyAdminUrl}contests/`,
    //     method: "POST",
    //     body,
    //   }),

    //   invalidatesTags: ["Contests"],
    // }),

    // ** add Contest
    addContest: builder.mutation({
      query: (body) => ({
        url: `${cricketFantasyAdminUrl}contest-creation-manually/`,
        method: "POST",
        body,
      }),

      invalidatesTags: ["Contests"],
    }),

    getAllContests: builder.query({
      query: (data) =>
        `${cricketFantasyAdminUrl}contests/?limit=${data.limit}&offset=${data?.offset}`,

      providesTags: ["Contests"],
    }),

    // ** get Context details by slug
    getContestDetailsBySlug: builder.query({
      query: (data) =>
        `${cricketFantasyAdminUrl}contest-details/${data?.slug}/`,
    }),

    getContestLeaderboard: builder.query({
      query: (data) =>
        `${cricketFantasyAdminUrl}contests-leaderboard/${data?.slug}/${data.params}`,
    }),

    getAllVirtualWallets: builder.query({
      query: (data) =>
        `${walletAdminV2Url}virtual-wallet-all/?limit=${data?.limit}&offset=${data?.offset}&owner=${data?.userSlug}&search=${data?.search}`,
    }),

    getUserVirtualWallets: builder.query({
      query: (data) => `${walletAdminUrl}user-virtual-wallet/${data.slug}/`,
    }),

    getVirtualWalletTransactions: builder.query({
      query: (data) =>
        `${walletAdminV2Url}user-virtual-wallet-transactions/${data?.slug}/?limit=${data?.limit}&offset=${data?.offset}`,

      providesTags: ["Wallets"],
    }),

    getAllWithdrawRequests: builder.query({
      query: (data) => `${walletAdminUrl}withdraw-request-all/${data?.params}`,

      providesTags: ["Wallets"],
    }),

    approveWithdrawRequest: builder.mutation({
      query: (data) => ({
        url: `${bitgoAdminUrl}approve-withdraw-transaction/${data?.slug}/`,
        method: "POST",
        body: data?.data,
      }),

      invalidatesTags: ["Wallets"],
    }),

    cancelWithdrawRequest: builder.mutation({
      query: (data) => ({
        url: `${bitgoAdminUrl}cancel-withdraw-request/${data?.slug}/`,
        method: "POST",
        body: data?.data,
      }),

      invalidatesTags: ["Wallets"],
    }),

    sendEmailOtp: builder.mutation({
      query: (data) => ({
        url: `${accountsAdminUrl}user-email-check/`,
        method: "POST",
        body: data,
      }),
    }),

    // ** Upcoming Matches
    getUpcomingMatches: builder.query({
      query: (data) =>
        `${cricketDataAdminUrl}match-2-months-list/?limit=${data.limit}&offset=${data?.offset}`,
    }),

    // ** get Ongoing and Upcoming Matches
    getOnGoingAndUpcomingMatches: builder.query({
      query: (data) =>
        `${cricketFantasyAdminUrl}contest-ongoing-completed-matches/?status=${data?.status}&limit=${data.limit}&offset=${data?.offset}`,
    }),

    // ** User Wallet Transactions
    userWalletTransactions: builder.query({
      query: (data) =>
        `${walletAdminV2Url}virtual-wallet-transactions-all/${data.slug}/?limit=${data.limit}&offset=${data.offset}&main_purpose=${data.main_purpose}`,
    }),

    // ** update Match Status in completed matches
    updateCompetedMatchStatus: builder.mutation({
      query: (data) => ({
        url: `${cricketFantasyAdminUrl}update-match-status/${data?.slug}/`,
        method: "PUT",
        body: data.data,
      }),

      invalidatesTags: ["CricketMatches"],
    }),

    getAdsStats: builder.query({
      query: (data) => `${adsAdmin}adds-stats/`,

      providesTags: ["Ads"],
    }),

    getAllAds: builder.query({
      query: (data) =>
        `${adsAdmin}all-adds/?limit=${data.limit}&offset=${data.offset}&from_date=${data.fromDate}`,

      providesTags: ["Ads"],
    }),

    createAd: builder.mutation({
      query: (data) => ({
        url: `${adsAdmin}all-adds/`,
        method: "POST",
        body: data,
      }),

      invalidatesTags: ["Ads"],
    }),

    getAddDetails: builder.query({
      query: (data) => `${adsAdmin}add-details/${data.slug}/`,
    }),

    getAllTransactions: builder.query({
      query: (data) =>
        `${walletAdminV2Url}transactions-all/?main_purpose=${data.purpose}&limit=${data.limit}&offset=${data.offset}&from_date=${data.fromDate}&to_date=${data.toDate}&search=${data.search}`,

      providesTags: ["Transactions"],
    }),

    getTransactionDetails: builder.query({
      query: (data) => `${walletAdminV2Url}transactions-details/${data.slug}/`,
    }),

    // ** delete advertisement
    deleteAd: builder.mutation({
      query: (data) => ({
        url: `${adsAdmin}add-details/${data.slug}/`,
        method: "DELETE",
        body: data.data,
      }),

      invalidatesTags: ["Ads"],
    }),

    updateAd: builder.mutation({
      query: (data) => ({
        url: `${adsAdmin}add-details/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),

      invalidatesTags: ["Ads"],
    }),

    //*** -------------------------------------NVRR-------------------------------------------------------- */
    //*** -------------------------------------SportsData--------------------- start*/
    //*** ------------------------Cricket--------------------- */
    //* SERIES ***
    //* All Series
    getAllCricketSeriesData: builder.query({
      query: (data) =>
        data.params
          ? `${cricketDataAdminUrl}series-list/${data.params}`
          : `${cricketDataAdminUrl}series-list/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,

      providesTags: ["CricketSeries"],
    }),
    //* All Series Matches
    getAllCricketSeriesMatchesBySlug: builder.query({
      query: (data) =>
        `${cricketDataAdminUrl}all-series-matches/${data.slug}/?limit=${data.limit}&offset=${data.offset}&match_status_enum=${data.match_status_enum}&search=${data.search}`,
    }),

    //** MATCHES ***
    //* All Matches
    getAllCricketMatchesData: builder.query({
      query: (data) =>
        data?.params
          ? `${cricketDataAdminUrl}matches-all/${data.params}`
          : `${cricketDataAdminUrl}matches-all/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
    }),
    //* PLAYERS ***
    //* All Players
    getAllCricketPlayersData: builder.query({
      query: (data) => `${cricketDataAdminUrl}team-players-list/${data.params}`,
      providesTags: ["Players"],
    }),
    //*  Player Details
    getCricketPlayerDetailsBySlug: builder.query({
      query: (data) => `${cricketDataAdminUrl}all-players/${data.slug}`,
    }),

    //* MATCH Details ***
    //*  Match Score
    getMatchScoreBySlug: builder.query({
      query: (data) => `${cricketDataAdminUrl}match-score/${data.slug}/`,
    }),
    //*  Match data
    getMatchDataBySlug: builder.query({
      query: (data) => `${cricketDataAdminUrl}match-details/${data.slug}/`,
      providesTags: ["CricketMatches"],
    }),
    //*  Match Points data
    getMatchPointsDataBySlug: builder.query({
      query: (data) =>
        `${cricketDataAdminUrl}match-points-details/${data.slug}/`,
    }),
    //* All Match Players
    getAllMatchPlayersBySlug: builder.query({
      query: (data) => `${cricketDataAdminUrl}match-all-players/${data.slug}/`,
      providesTags: ["MatchPlayers"],
    }),
    //* All Match Players Points
    getAllMatchPlayersTotalPointsBySlug: builder.query({
      query: (data) =>
        `${cricketDataAdminUrl}match-all-players-points/${data.slug}/`,
      //&search=${data.search}
    }),

    //*** ------------------------SportsData---------------------end */

    //*** filter apis */
    //* All Teams
    // getAllTeams: builder.query({
    //   query: (data) => `${cricketDataAdminUrl}all-teams/?search=${data.search}`,
    // }),

    // ** Akhil
    // ** Fantasy Sports
    // ** All Contests
    getAllCricketContests: builder.query({
      query: (data) => `${cricketFantasyAdminUrl}contests/${data?.params}`,
      providesTags: ["CricketContests", "Contests"],
    }),
    // ** update contest Status
    updateContestStatus: builder.mutation({
      query: (data) => ({
        url: `${cricketFantasyAdminUrl}contests-details/${data?.slug}/`,
        method: "PUT",
        body: data?.data,
      }),

      invalidatesTags: ["CricketContests"],
    }),
    // ** update contest Status by slug
    updateContestStatusBySlug: builder.mutation({
      query: (data) => ({
        url: `${cricketFantasyAdminUrl}contest-approve-deny/${data?.slug}/`,
        method: "PUT",
        body: data?.data,
      }),

      invalidatesTags: ["CricketContests"],
    }),
    // ** Contest Details
    getContestDetails: builder.query({
      query: (data) =>
        `${cricketFantasyAdminUrl}contests-details/${data?.slug}/`,
      providesTags: ["CricketContests", "CricketMatches"],
    }),
    // ** Contest Winnings
    getContestWinnings: builder.query({
      query: (data) =>
        `${cricketFantasyAdminUrl}contests-winnings/${data?.slug}/`,
      providesTags: ["CricketContests"],
    }),

    // ** get upcoming matches
    getAllUpcomingMatches: builder.query({
      query: (data) =>
        `${cricketDataAdminUrl}upcoming-matches/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
    }),

    getContestBySlug: builder.query({
      query: (data) =>
        `${cricketFantasyAdminUrl}contest-winnings-update/${data.slug}/`,
    }),

    editContest: builder.mutation({
      query: (data) => ({
        url: `${cricketFantasyAdminUrl}contest-winnings-update/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),

      invalidatesTags: ["Contests"],
    }),

    getTeamPerviewDetails: builder.query({
      query: (data) =>
        `${cricketFantasyAdminUrl}team-player-points/${data.slug}`,
    }),

    //***----------------- Chats-------------------------
    // ** create Ticket
    createTicket: builder.mutation({
      query: (data) => ({
        url: `${ticketsAdminUrl}ticket-creation/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Tickets"],
    }),
    //*Users
    getAllUsersData: builder.query({
      query: (data) =>
        `${accountsAdminUrl}all-users/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      invalidatesTags: ["Users"],
    }),
    //*User details by slug
    getUserDetailsBySlug: builder.query({
      query: (data) => `${accountsAdminUrl}all-users/${data.slug}/`,
    }),
    // **chats prev messages of particular person or group
    getChatPreviousMessages: builder.query({
      query: (data) =>
        `${chatAdminUrl}group-messages/${data.slug}/?limit=${data.limit}&offset=${data.offset}`,

      // providesTags: ["Chat"],
    }),
    //** All Users */
    getAllUserss: builder.query({
      query: (data) => `${accountsAdminUrl}all-users/${data.params}`,
      // invalidatesTags: ["Users", "Staff"],
    }),
    //** ChatList
    getChatList: builder.query({
      query: (data) =>
        `${chatAdminUrl}group/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,

      providesTags: ["Chat"],
    }),

    //** ChatList
    getChatList: builder.query({
      query: (data) =>
        `${chatAdminUrl}group/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,

      providesTags: ["Chat"],
    }),

    //* Add Staff
    addNewStaff: builder.mutation({
      query: (data) => ({
        url: `${accountsAdminUrl}staff-creation/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),

    //** All Staff
    getAllStaff: builder.query({
      query: (data) => `${accountsAdminUrl}all-staff-users/${data.params}`,

      providesTags: ["Users"],
    }),

    //***----------------- Chats-end ------------------------
    //*..........shruthi..............*//

    getTicketStatsDetails: builder.query({
      query: (data) => `${ticketsAdminUrl}tickets-stats/`,
    }),

    getAllTickets: builder.query({
      query: (data) => `${ticketsAdminUrl}all-tickets/${data.params}`,
      providesTags: ["Tickets"],
    }),

    getTicketCustomerTicketsDetails: builder.query({
      query: (data) => `${ticketsAdminUrl}all-tickets/${data.params}`,
      providesTags: ["Tickets"],
    }),

    getMyTicketCustomerTicketDetails: builder.query({
      query: (data) => `${ticketsAdminUrl}all-tickets/${data.params}`,
      providesTags: ["Tickets"],
    }),
    //*** Users Details Tickets */
    getUserTicketsBySlug: builder.query({
      query: (data) => `${ticketsAdminUrl}all-tickets/${data.params}`,
    }),

    //** get Ticket detail by slug */
    getTicketDetailsBySlug: builder.query({
      query: (data) => `${ticketsAdminUrl}ticket-details/${data.slug}/`,
      providesTags: ["Tickets"],
    }),
    // ** Edit Ticket
    editTicket: builder.mutation({
      query: (data) => ({
        url: `${ticketsAdminUrl}ticket-details/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["Tickets"],
    }),

    //** Ticket Tracking */
    getTicketTrackingDetailsBySlug: builder.query({
      query: (data) => `${ticketsAdminUrl}ticket-tracking/${data.slug}/`,
      providesTags: ["Tickets"],
    }),

    //* Resend Ticket
    resendTicketBySlug: builder.mutation({
      query: (data) => ({
        url: `${ticketsAdminUrl}ticket-resend/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),

      invalidatesTags: ["Tickets"],
    }),

    // ** Fantasy Sports
    // ** All  Cricket Fantasy Rules
    getAllCricketFantasyRules: builder.query({
      query: (data) => `${pointsAdminUrl}match-main-points/${data.params}`,
      providesTags: ["CricketFantasyFormatRules"],
    }),

    //**  create Cricket Fantasy Format Rules*/
    createCricketFantasyFormatRules: builder.mutation({
      query: (data) => ({
        url: `${pointsAdminUrl}match-main-points/`,
        method: "POST",
        body: data.data,
      }),
      invalidatesTags: ["CricketFantasyFormatRules"],
    }),

    //**  create Cricket Fantasy Format Rules*/
    createCricketFantasyFormatRulesWithBulkUpload: builder.mutation({
      query: (data) => ({
        url: `custom_utils/api/client/v1/points-bulk-upload/`,
        method: "POST",
        body: data.data,
      }),
      invalidatesTags: ["CricketFantasyFormatRules", "BulkUpload"],
    }),
    //* bulk upload
    getAllCricketFantasyRulesBulkUploads: builder.query({
      query: (data) => `custom_utils/api/client/v1/points-bulk-upload/`,
      providesTags: ["BulkUpload"],
    }),

    //  ** update Cricket Points By Slug
    updateCricketPointsBySlug: builder.mutation({
      query: (data) => ({
        url: `${pointsAdminUrl}all-points-update/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["Points"],
    }),

    //**  add Points By Slug */
    addCricketPointsBySlug: builder.mutation({
      query: (data) => ({
        url: `${pointsAdminUrl}all-points-create/${data.slug}/`,
        method: "POST",
        body: data.data,
      }),
      invalidatesTags: ["Points"],
    }),

    // ** All  Cricket Points By slug
    getAllCricketPointsBySlug: builder.query({
      query: (data) =>
        `${pointsAdminUrl}match-main-points/${data.slug}/?limit=${data.limit}&offset=${data.offset}`,
      providesTags: ["Points"],
    }),

    //**Cricket contest match assigned points by slug */
    getAllCricketContestMatchAssignedPointsBySlug: builder.query({
      query: (data) =>
        `${cricketDataAdminUrl}match-assigned-points/${data.slug}/`,
      providesTags: ["CricketContestMatchAssignedPoints"],
    }),

    //**  add Points By Slug */
    updateCricketContestMatchAssignedPointsBySlug: builder.mutation({
      query: (data) => ({
        url: `${cricketDataAdminUrl}match-assigned-points/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["CricketContestMatchAssignedPoints"],
    }),

    // ** get All Payments of Contest
    getAllContestPayments: builder.query({
      query: (data) =>
        `${cricketFantasyAdminUrl}contest-transactions/${data?.slug}/${data?.params}`,
      providesTags: ["ContestTransactions"],
    }),

    //* all teams
    getAllCricketTeams: builder.query({
      query: (data) => `${cricketDataAdminUrl}teams-all/${data.params}`,
      providesTags: ["CricketTeams"],
    }),

    //** Edit team By Slug */
    editTeamBySlug: builder.mutation({
      query: (data) => ({
        url: `${cricketDataAdminUrl}teams-all/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["CricketTeams"],
    }),

    // ** get fantacy crciket transaction stats
    getPaymentStatsOfContest: builder.query({
      query: (data) =>
        `${cricketFantasyAdminUrl}contest-payment-stats/${data?.slug}/`,
    }),

    //*** NFTs */
    // ** get All Nft Users
    getAllNftUsers: builder.query({
      query: (data) => `${nftAdminUrl}get-user-nfts/${data.params}`,
      providesTags: ["Nfts"],
    }),
    //** Add team By Slug */
    addNftUser: builder.mutation({
      query: (data) => ({
        url: `${nftAdminUrl}create-nft/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Nfts"],
    }),
    // ** get Nft UserDetails By Slug
    getNftUserDetailsBySlug: builder.query({
      query: (data) => `${nftAdminUrl}get-nft-metadata/${data.slug}/`,
      providesTags: ["Nfts"],
    }),

    // ** get Nft UserDetails By Slug
    getNftUserTransactionsBySlug: builder.query({
      query: (data) => `${nftAdminUrl}get-nft-transactions/${data.slug}/`,
      providesTags: ["Nfts"],
    }),

    //*** NFT Wallets */
    // ** get Nft UserDetails By Slug
    getNftWalletsData: builder.query({
      query: (data) => `${nftAdminUrl}user-wallet/`,
      providesTags: ["NftWallets"],
    }),

    //*** NFT Status */
    // ** get All Nft Status Data
    getAllNftStatusData: builder.query({
      query: (data) => `${nftAdminUrl}all-uploaded-nfts/${data.params}`,
      providesTags: ["NftStatus"],
    }),

    // ** get Nft status Details By Slug
    getNftStatusDetailsBySlug: builder.query({
      query: (data) => `${nftAdminUrl}uploaded-nft-status/${data.slug}/`,
      // providesTags: ["NftStatus"],
    }),

    // ** get All Nfts
    getAllNfts: builder.query({
      query: (data) =>
        `${nftAdminUrl}get-all-nfts/?distributed=${data.distributed}`,
      // providesTags: ["NftStatus"],
    }),

    //** Edit Series By Slug */
    editSeriesNameBySlug: builder.mutation({
      query: (data) => ({
        url: `${cricketDataAdminUrl}series-list/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["CricketSeries"],
    }),

    //** Edit cricket Player Details By Slug */
    editCricketPlayerDetailsBySlug: builder.mutation({
      query: (data) => ({
        url: `${cricketDataAdminUrl}all-players/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["Players"],
    }),

    //** Add Players By Slug */
    addCricketPlayersBySlug: builder.mutation({
      query: (data) => ({
        url: `${cricketDataAdminUrl}assign-players-match/${data.slug}/`,
        method: "POST",
        body: data.data,
      }),
      invalidatesTags: ["MatchPlayers"],
    }),

    //** Add contest transaction Refund By Slug */
    addCricketContextTransactionRefundBySlug: builder.mutation({
      query: (data) => ({
        url: `${walletAdminV2Url}transaction-refund/${data.slug}/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ContestTransactions"],
    }),

    // get all Transaction
    getAllTransactionsList: builder.query({
      query: (data) => `${walletAdminV2Url}wallets-overview/`,
    }),

    //*** Bonus */

    getBonusAllTransactions: builder.query({
      query: (data) =>
        `${walletAdminV2Url}transactions-all/?limit=${data.limit}&offset=${data.offset}&search=${data.search}&fromDate=${data.fromDate}&toDate=${data.toDate}&main_purpose=${data.main_purpose}`,

      providesTags: ["Bonus"],
    }),

    // ** Get All Users
    getBonusAllUsers: builder.query({
      query: (data) =>
        `${accountsAdminUrl}all-users/?limit=${data.limit}&offset=${data.offset}&search=${data.search}&fromDate=${data.fromDate}&toDate=${data.toDate}`,
      providesTags: ["Users"],
    }),

    //***********************************Bonus****************************************************//
    //post multiple bonus
    postMultipleBonusToUsers: builder.mutation({
      query: (data) => ({
        url: `${walletAdminV2Url}add-multiple-bonus-amount/`,
        method: `POST`,
        body: data,
      }),

      invalidatesTags: ["Bonus"],
    }),
    //***********************************Bonus****************************************************//
    //* All Kyc
    getAllKycDetails: builder.query({
      query: (data) => `${kycAdminUrl}get-all-pan-details/${data.params}`,
      providesTags: ["KycDetails"],
    }),

    getAllAadharKycDetails: builder.query({
      query: (data) => `${kycAdminUrl}get-all-aadhar-details/${data.params}`,
      providesTags: ["KycDetails"],
    }),

    //*  Kyc Details By Slug
    getAllKycDetailsBySlug: builder.query({
      query: (data) => `${kycAdminUrl}get-a-pan-details/${data.slug}/`,
      providesTags: ["KycDetails"],
    }),

    getAllAadharKycDetailsBySlug: builder.query({
      query: (data) => `${kycAdminUrl}get-a-aadhar-details/${data.slug}/`,
      providesTags: ["KycDetails"],
    }),

    //* Update Kyc Details By Slug
    updateKycDetailsBySlug: builder.mutation({
      query: (data) => ({
        url: `${kycAdminUrl}verify-pan/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["KycDetails"],
    }),

    updateAadharKycDetailsBySlug: builder.mutation({
      query: (data) => ({
        url: `${kycAdminUrl}verify-aadhar/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["KycDetails"],
    }),

    //***********************************Bonus****************************************************//
    //* All Videos And Feed
    getAllVideoGallery: builder.query({
      query: (data) => `${videoGalleryAdminUrl}all-posts/${data.params}`,
      providesTags: ["AllVideoGallery"],
    }),
    getAllVideoGalleryTypes: builder.query({
      query: (data) =>
        data?.search
          ? `${videoGalleryAdminUrl}all-events/?limit=${data.limit}&search=${data.search}`
          : `${videoGalleryAdminUrl}all-events/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,

      providesTags: ["AllVideoGalleryTypes"],
    }),

    deleteVideoGallery: builder.mutation({
      query: (data) => ({
        url: `${videoGalleryAdminUrl}delete-video/${data.slug}/`,
        method: "DELETE",
      }),

      invalidatesTags: ["AllVideoGallery"],
    }),

    deleteVideoFeed: builder.mutation({
      query: (data) => ({
        url: `${feedAdminUrl}delete-video/${data.slug}/`,
        method: "DELETE",
      }),

      invalidatesTags: ["AllFeed"],
    }),

    updateAssignVideoGalleryTypeBySlug: builder.mutation({
      query: (data) => ({
        url: `${videoGalleryAdminUrl}assign-video/${data.slug}/`,
        method: "POST",
        body: data.data,
      }),
      invalidatesTags: ["AllVideoGallery"],
    }),

    updateApproveVideoGalleryBySlug: builder.mutation({
      query: (data) => ({
        url: `${videoGalleryAdminUrl}approve-content/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["AllVideoGallery"],
    }),

    updateApproveVideoFeedBySlug: builder.mutation({
      query: (data) => ({
        url: `${feedAdminUrl}approve-content/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["AllFeed"],
    }),

    postVideoGalleryCreate: builder.mutation({
      query: (data) => ({
        url: `${videoGalleryAdminUrl}create-post/`,
        method: `POST`,
        body: data,
      }),

      invalidatesTags: ["AllVideoGallery"],
    }),

    postVideoFeedCreate: builder.mutation({
      query: (data) => ({
        url: `${feedAdminUrl}create-post/`,
        method: `POST`,
        body: data,
      }),

      invalidatesTags: ["AllFeed"],
    }),

    postAttachmentCreate: builder.mutation({
      query: (data) => ({
        url: `${feedAdminUrl}file/`,
        method: `POST`,
        body: data,
      }),
    }),

    getAllFeed: builder.query({
      query: (data) => `${feedAdminUrl}all-posts/${data.params}`,
      providesTags: ["AllFeed"],
    }),
  }),
});

export const {
  // kyc modules
  useGetAllKycDetailsQuery,
  useGetAllAadharKycDetailsQuery,

  useUpdateKycDetailsBySlugMutation,
  useUpdateAadharKycDetailsBySlugMutation,
  //** */

  // video gallery and feed modules

  useGetAllVideoGalleryQuery,
  useUpdateApproveVideoGalleryBySlugMutation,
  useUpdateAssignVideoGalleryTypeBySlugMutation,
  useUpdateApproveVideoFeedBySlugMutation,

  useGetAllVideoGalleryTypesQuery,
  useGetAllFeedQuery,
  usePostVideoGalleryCreateMutation,
  usePostVideoFeedCreateMutation,
  usePostAttachmentCreateMutation,

  useDeleteVideoGalleryMutation,
  useDeleteVideoFeedMutation,

  //** */
  useSendOtpMutation,
  useGetAllMatchesQuery,
  useGetAllSeriesQuery,
  useGetAllTeamsQuery,
  useGetAllPlayersQuery,
  useGetAllSeriesDetailsQuery,
  useGetAllSeriesMatchesQuery,
  useGetAllMatchesDetailsQuery,
  useGetAllUsersQuery,
  useGetUserWalletsQuery,
  useGetUserContestsQuery,
  useGetUserDetailsQuery,
  useGetContestsByMatchQuery,
  useGetAllWalletsQuery,
  useGetWalletTransactionsQuery,
  useGetUserSelectionTeamQuery,
  useAddContestMutation,
  useGetAllContestsQuery,
  useGetContestDetailsBySlugQuery,
  useGetContestLeaderboardQuery,
  useGetAllVirtualWalletsQuery,
  useGetUserVirtualWalletsQuery,
  useGetVirtualWalletTransactionsQuery,
  useGetAllWithdrawRequestsQuery,
  useApproveWithdrawRequestMutation,
  useCancelWithdrawRequestMutation,
  useSendEmailOtpMutation,
  useGetUpcomingMatchesQuery,
  useGetOnGoingAndUpcomingMatchesQuery,
  useUserWalletTransactionsQuery,
  useUpdateCompetedMatchStatusMutation,
  useGetAdsStatsQuery,
  useGetAllTransactionsQuery,
  useDeleteAdMutation,
  useUpdateAdMutation,

  //***-------------nvrr------------ */
  useGetAllCricketMatchesDataQuery,
  useGetAllCricketPlayersDataQuery,
  useGetCricketPlayerDetailsBySlugQuery,
  useGetMatchScoreBySlugQuery,
  useGetMatchDataBySlugQuery,
  useGetAllMatchPlayersTotalPointsBySlugQuery,
  useGetAllMatchPlayersBySlugQuery,
  useGetMatchPointsDataBySlugQuery,
  useGetAllCricketSeriesDataQuery,
  useGetAllCricketSeriesMatchesBySlugQuery,
  //** */
  useGetAllCricketContestsQuery,
  useUpdateContestStatusMutation,
  useGetContestDetailsQuery,
  useGetContestWinningsQuery,
  useGetAllUpcomingMatchesQuery,
  useGetContestBySlugQuery,
  useEditContestMutation,
  useGetTeamPerviewDetailsQuery,
  useUpdateUserActiveInactiveMutation,
  //*
  useCreateTicketMutation,
  useGetAllUsersDataQuery,
  useGetUserDetailsBySlugQuery,
  useLazyGetChatPreviousMessagesQuery,
  useGetAllUserssQuery,
  useGetChatListQuery,
  useAddNewStaffMutation,
  useGetAllStaffQuery,
  useGetTicketStatsDetailsQuery,
  useGetTicketCustomerTicketsDetailsQuery,
  useGetMyTicketCustomerTicketDetailsQuery,
  useGetUserTicketsBySlugQuery,
  useGetTicketDetailsBySlugQuery,
  useEditTicketMutation,
  useGetTicketTrackingDetailsBySlugQuery,
  useResendTicketBySlugMutation,
  useGetAllAdsQuery,
  useCreateAdMutation,
  useGetAddDetailsQuery,
  useGetTransactionDetailsQuery,
  useCreateCricketFantasyFormatRulesWithBulkUploadMutation,
  useCreateCricketFantasyFormatRulesMutation,
  useGetAllCricketFantasyRulesQuery,
  useUpdateCricketPointsBySlugMutation,
  useAddCricketPointsBySlugMutation,
  useGetAllCricketPointsBySlugQuery,
  useGetAllCricketFantasyRulesBulkUploadsQuery,
  useGetAllCricketContestMatchAssignedPointsBySlugQuery,
  useUpdateCricketContestMatchAssignedPointsBySlugMutation,
  useUpdateContestStatusBySlugMutation,
  useGetAllContestPaymentsQuery,
  useGetAllCricketTeamsQuery,
  useEditTeamBySlugMutation,
  useGetPaymentStatsOfContestQuery,
  //* NFTs
  useGetAllNftUsersQuery,
  useAddNftUserMutation,
  useGetNftUserDetailsBySlugQuery,
  useGetNftUserTransactionsBySlugQuery,
  useGetNftWalletsDataQuery,
  useGetAllNftStatusDataQuery,
  useGetNftStatusDetailsBySlugQuery,
  useGetAllNftsQuery,
  useGetAllTicketsQuery,
  useEditSeriesNameBySlugMutation,
  useEditCricketPlayerDetailsBySlugMutation,
  useAddCricketPlayersBySlugMutation,
  useAddCricketContextTransactionRefundBySlugMutation,

  // All Transctions
  useGetAllTransactionsListQuery,
  useGetAllPlayersByTeamSlugQuery,

  useGetBonusAllTransactionsQuery,
  useGetBonusAllUsersQuery,

  //bonus
  usePostMultipleBonusToUsersMutation,
} = restApi;

export default restApi;
