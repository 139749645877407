import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userTransactionPopUp: {
    status: false,
    helperData: null,
  },
};
const transaction = createSlice({
  name: "transaction",
  initialState: initialState,
  reducers: {
    openuserTransactionPopUp(state, action) {
      state.userTransactionPopUp.helperData = action.payload;
      state.userTransactionPopUp.status = true;
    },
    closeuserTransactionPopUp(state, action) {
      state.userTransactionPopUp.status = false;
      state.userTransactionPopUp.helperData = action.payload;
    },
  },
});
export const { openuserTransactionPopUp, closeuserTransactionPopUp } =
  transaction.actions;
export default transaction.reducer;
