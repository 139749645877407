import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  video: {
    showVideoGalleryDetailsPopup: { status: false, helperData: null },
    showVideoGalleryCreatePopup: { status: false, helperData: null },

    showVideoFeedDetailsPopup: { status: false, helperData: null },
    showVideoFeedCreatePopup: { status: false, helperData: null },
    // showBankDetailsPopup: { status: false, helperData: null },
    showEditVideoGalleryDetailsPopup: { status: false, helperData: null },
    showEditVideoFeedDetailsPopup: { status: false, helperData: null },
    // showEditBankDetailsPopup: { status: false, helperData: null },
    showVideoGalleryDeniedPopup: { status: false, helperData: null },
    showVideoFeedDeniedPopup: { status: false, helperData: null },
  },
  // prints: {
  //   showInvoicePrintPopup: { status: false, helperData: null },
  // },
};

const videosSlice = createSlice({
  name: "videos",
  initialState: initialState,
  reducers: {
    //* show Kyc Denied Popup
    openShowVideoGalleryDeniedPopup(state, action) {
      state.video.showVideoGalleryDeniedPopup.helperData = action.payload;
      state.video.showVideoGalleryDeniedPopup.status = true;
    },
    closeShowVideoGalleryDeniedPopup(state, action) {
      state.video.showVideoGalleryDeniedPopup.status = false;
      state.video.showVideoGalleryDeniedPopup.helperData = null;
    },

    openShowVideoFeedDeniedPopup(state, action) {
      state.video.showVideoFeedDeniedPopup.helperData = action.payload;
      state.video.showVideoFeedDeniedPopup.status = true;
    },

    closeShowVideoFeedDeniedPopup(state, action) {
      state.video.showVideoFeedDeniedPopup.status = false;
      state.video.showVideoFeedDeniedPopup.helperData = null;
    },

    //* show Kyc Details Popup
    openShowVideoGalleryDetailsPopup(state, action) {
      state.video.showVideoGalleryDetailsPopup.helperData = action.payload;
      state.video.showVideoGalleryDetailsPopup.status = true;
    },
    closeShowVideoGalleryDetailsPopup(state, action) {
      state.video.showVideoGalleryDetailsPopup.status = false;
      state.video.showVideoGalleryDetailsPopup.helperData = null;
    },
    openShowVideoGalleryCreatePopup(state, action) {
      state.video.showVideoGalleryCreatePopup.helperData = action.payload;
      state.video.showVideoGalleryCreatePopup.status = true;
    },
    closeShowVideoGalleryCreatePopup(state, action) {
      state.video.showVideoGalleryCreatePopup.status = false;
      state.video.showVideoGalleryCreatePopup.helperData = null;
    },
    openShowVideoFeedCreatePopup(state, action) {
      state.video.showVideoFeedCreatePopup.helperData = action.payload;
      state.video.showVideoFeedCreatePopup.status = true;
    },
    closeShowVideoFeedCreatePopup(state, action) {
      state.video.showVideoFeedCreatePopup.status = false;
      state.video.showVideoFeedCreatePopup.helperData = null;
    },
    openShowVideoFeedDetailsPopup(state, action) {
      state.video.showVideoFeedDetailsPopup.helperData = action.payload;
      state.video.showVideoFeedDetailsPopup.status = true;
    },
    closeShowVideoFeedDetailsPopup(state, action) {
      state.video.showVideoFeedDetailsPopup.status = false;
      state.video.showVideoFeedDetailsPopup.helperData = null;
    },

    //* show Edit Kyc Details Popup
    openShowEditVideoGalleryDetailsPopup(state, action) {
      state.video.showEditVideoGalleryDetailsPopup.helperData = action.payload;
      state.video.showEditVideoGalleryDetailsPopup.status = true;
    },
    closeShowEditVideoGalleryDetailsPopup(state, action) {
      state.video.showEditVideoGalleryDetailsPopup.status = false;
      state.video.showEditVideoGalleryDetailsPopup.helperData = null;
    },
    openShowEditVideoFeedDetailsPopup(state, action) {
      state.video.showEditVideoFeedDetailsPopup.helperData = action.payload;
      state.video.showEditVideoFeedDetailsPopup.status = true;
    },

    closeShowEditVideoFeedDetailsPopup(state, action) {
      state.video.showEditVideoFeedDetailsPopup.status = false;
      state.video.showEditVideoFeedDetailsPopup.helperData = null;
    },

    // //* show Bank Details Popup
    // openShowBankDetailsPopup(state, action) {
    //   state.kyc.showBankDetailsPopup.helperData = action.payload;
    //   state.kyc.showBankDetailsPopup.status = true;
    // },
    // closeShowBankDetailsPopup(state, action) {
    //   state.kyc.showBankDetailsPopup.status = false;
    //   state.kyc.showBankDetailsPopup.helperData = null;
    // },

    // //* show Edit Bank Details Popup
    // openShowEditBankDetailsPopup(state, action) {
    //   state.kyc.showEditBankDetailsPopup.helperData = action.payload;
    //   state.kyc.showEditBankDetailsPopup.status = true;
    // },
    // closeShowEditBankDetailsPopup(state, action) {
    //   state.kyc.showEditBankDetailsPopup.status = false;
    //   state.kyc.showEditBankDetailsPopup.helperData = null;
    // },

    // //***Prints --start */
    // //* show Invoice Print Popup
    // openShowInvoicePrintPopup(state, action) {
    //   state.prints.showInvoicePrintPopup.helperData = action.payload;
    //   state.prints.showInvoicePrintPopup.status = true;
    // },
    // closeShowInvoicePrintPopup(state, action) {
    //   state.prints.showInvoicePrintPopup.status = false;
    //   state.prints.showInvoicePrintPopup.helperData = null;
    // },
    //***Prints --end */
  },
});

export const {
  // openShowBankDetailsPopup,
  // closeShowBankDetailsPopup,
  openShowVideoGalleryDetailsPopup,
  closeShowVideoGalleryDetailsPopup,
  openShowVideoGalleryCreatePopup,
  closeShowVideoGalleryCreatePopup,
  openShowVideoFeedDetailsPopup,
  closeShowVideoFeedDetailsPopup,
  openShowVideoFeedCreatePopup,
  closeShowVideoFeedCreatePopup,
  // openShowEditBankDetailsPopup,
  // closeShowEditBankDetailsPopup,
  openShowEditVideoGalleryDetailsPopup,
  closeShowEditVideoGalleryDetailsPopup,
  openShowEditVideoFeedDetailsPopup,
  closeShowEditVideoFeedDetailsPopup,
  openShowVideoGalleryDeniedPopup,
  closeShowVideoGalleryDeniedPopup,
  openShowVideoFeedDeniedPopup,
  closeShowVideoFeedDeniedPopup,
  //*
  // openShowInvoicePrintPopup,
  // closeShowInvoicePrintPopup,
} = videosSlice.actions;
export default videosSlice.reducer;
