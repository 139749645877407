import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  kyc: {
    showKycDetailsPopup: { status: false, helperData: null },
    showKycAadharDetailsPopup: { status: false, helperData: null },
    // showBankDetailsPopup: { status: false, helperData: null },
    showEditKycDetailsPopup: { status: false, helperData: null },
    showEditKycAadharDetailsPopup: { status: false, helperData: null },

    // showEditBankDetailsPopup: { status: false, helperData: null },
    showKycDeniedPopup: { status: false, helperData: null },
    showKycAadharDeniedPopup: { status: false, helperData: null },
  },
  // prints: {
  //   showInvoicePrintPopup: { status: false, helperData: null },
  // },
};

const kycBankSlice = createSlice({
  name: "kycbank",
  initialState: initialState,
  reducers: {
    //* show Kyc Denied Popup
    openShowKycDeniedPopup(state, action) {
      state.kyc.showKycDeniedPopup.helperData = action.payload;
      state.kyc.showKycDeniedPopup.status = true;
    },
    closeShowKycDeniedPopup(state, action) {
      state.kyc.showKycDeniedPopup.status = false;
      state.kyc.showKycDeniedPopup.helperData = null;
    },

    openShowKycAadharDeniedPopup(state, action) {
      state.kyc.showKycAadharDeniedPopup.helperData = action.payload;
      state.kyc.showKycAadharDeniedPopup.status = true;
    },

    closeShowKycAadharcDeniedPopup(state, action) {
      state.kyc.showKycAadharDeniedPopup.status = false;
      state.kyc.showKycAadharDeniedPopup.helperData = null;
    },

    //* show Kyc Details Popup
    openShowKycDetailsPopup(state, action) {
      state.kyc.showKycDetailsPopup.helperData = action.payload;
      state.kyc.showKycDetailsPopup.status = true;
    },
    closeShowKycDetailsPopup(state, action) {
      state.kyc.showKycDetailsPopup.status = false;
      state.kyc.showKycDetailsPopup.helperData = null;
    },

    openShowKycAadharDetailsPopup(state, action) {
      state.kyc.showKycAadharDetailsPopup.helperData = action.payload;
      state.kyc.showKycAadharDetailsPopup.status = true;
    },
    closeShowKycAadharDetailsPopup(state, action) {
      state.kyc.showKycAadharDetailsPopup.status = false;
      state.kyc.showKycAadharDetailsPopup.helperData = null;
    },

    //* show Edit Kyc Details Popup
    openShowEditKycDetailsPopup(state, action) {
      state.kyc.showEditKycDetailsPopup.helperData = action.payload;
      state.kyc.showEditKycDetailsPopup.status = true;
    },
    closeShowEditKycDetailsPopup(state, action) {
      state.kyc.showEditKycDetailsPopup.status = false;
      state.kyc.showEditKycDetailsPopup.helperData = null;
    },
    openShowEditKycAadharDetailsPopup(state, action) {
      state.kyc.showEditKycAadharDetailsPopup.helperData = action.payload;
      state.kyc.showEditKycAadharDetailsPopup.status = true;
    },

    closeShowEditKycAadharDetailsPopup(state, action) {
      state.kyc.showEditKycAadharDetailsPopup.status = false;
      state.kyc.showEditKycAadharDetailsPopup.helperData = null;
    },

    // //* show Bank Details Popup
    // openShowBankDetailsPopup(state, action) {
    //   state.kyc.showBankDetailsPopup.helperData = action.payload;
    //   state.kyc.showBankDetailsPopup.status = true;
    // },
    // closeShowBankDetailsPopup(state, action) {
    //   state.kyc.showBankDetailsPopup.status = false;
    //   state.kyc.showBankDetailsPopup.helperData = null;
    // },

    // //* show Edit Bank Details Popup
    // openShowEditBankDetailsPopup(state, action) {
    //   state.kyc.showEditBankDetailsPopup.helperData = action.payload;
    //   state.kyc.showEditBankDetailsPopup.status = true;
    // },
    // closeShowEditBankDetailsPopup(state, action) {
    //   state.kyc.showEditBankDetailsPopup.status = false;
    //   state.kyc.showEditBankDetailsPopup.helperData = null;
    // },

    // //***Prints --start */
    // //* show Invoice Print Popup
    // openShowInvoicePrintPopup(state, action) {
    //   state.prints.showInvoicePrintPopup.helperData = action.payload;
    //   state.prints.showInvoicePrintPopup.status = true;
    // },
    // closeShowInvoicePrintPopup(state, action) {
    //   state.prints.showInvoicePrintPopup.status = false;
    //   state.prints.showInvoicePrintPopup.helperData = null;
    // },
    //***Prints --end */
  },
});

export const {
  // openShowBankDetailsPopup,
  // closeShowBankDetailsPopup,
  openShowKycDetailsPopup,
  closeShowKycDetailsPopup,
  openShowKycAadharDetailsPopup,
  closeShowKycAadharDetailsPopup,
  // openShowEditBankDetailsPopup,
  // closeShowEditBankDetailsPopup,
  openShowEditKycDetailsPopup,
  closeShowEditKycDetailsPopup,
  openShowEditKycAadharDetailsPopup,
  closeShowEditKycAadharDetailsPopup,
  openShowKycDeniedPopup,
  closeShowKycDeniedPopup,
  openShowKycAadharDeniedPopup,
  closeShowKycAadharcDeniedPopup,
  //*
  // openShowInvoicePrintPopup,
  // closeShowInvoicePrintPopup,
} = kycBankSlice.actions;
export default kycBankSlice.reducer;
