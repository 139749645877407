import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addContestPopup: { status: false, helperData: null },
  editContestPopup: { status: false, helperData: null },
  priceDistributionPopup: { status: false, helperData: null },
  teamPerviewPopup: { status: false, helperData: null },
};

const cricketSlice = createSlice({
  name: "cricket",
  initialState: initialState,
  reducers: {
    openAddContestPopup(state, action) {
      state.addContestPopup.helperData = action.payload;
      state.addContestPopup.status = true;
    },
    closeAddContestPopup(state, action) {
      state.addContestPopup.status = false;
      state.addContestPopup.helperData = null;
    },

    openEditContestPopup(state, action) {
      state.editContestPopup.helperData = action.payload;
      state.editContestPopup.status = true;
    },
    closeEditContestPopup(state, action) {
      state.editContestPopup.status = false;
      state.editContestPopup.helperData = null;
    },

    openPriceDistributionPopup(state, action) {
      state.priceDistributionPopup.helperData = action.payload;
      state.priceDistributionPopup.status = true;
    },
    closePriceDistributionPopup(state, action) {
      state.priceDistributionPopup.status = false;
      state.priceDistributionPopup.helperData = null;
    },

    openTeamPerviewPopup(state, action) {
      state.teamPerviewPopup.helperData = action.payload;
      state.teamPerviewPopup.status = true;
    },
    closeTeamPerviewPopup(state, action) {
      state.teamPerviewPopup.status = false;
      state.teamPerviewPopup.helperData = null;
    },
  },
});

export const {
  openAddContestPopup,
  closeAddContestPopup,
  openPriceDistributionPopup,
  closePriceDistributionPopup,
  openEditContestPopup,
  closeEditContestPopup,
  openTeamPerviewPopup,
  closeTeamPerviewPopup,
} = cricketSlice.actions;
export default cricketSlice.reducer;
