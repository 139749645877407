import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedUser: {},
  showCreateCustomerTicketPopup: { status: false, helperData: null },
};

const chatsSlice = createSlice({
  name: "chats",
  initialState: initialState,
  reducers: {
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },

    //* show Create CustomerTicketPopup
    openShowCreateCustomerTicketPopup(state, action) {
      state.showCreateCustomerTicketPopup.helperData = action.payload;
      state.showCreateCustomerTicketPopup.status = true;
    },
    closeShowCreateCustomerTicketPopup(state, action) {
      state.showCreateCustomerTicketPopup.status = false;
      state.showCreateCustomerTicketPopup.helperData = null;
    },
  },
});

export const {
  openShowCreateCustomerTicketPopup,
  closeShowCreateCustomerTicketPopup,
  setSelectedUser,
} = chatsSlice.actions;
export default chatsSlice.reducer;
